import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [

    {
        path: '/',
        name: 'home-page',
        component: () => import('@/view/home-page/index.vue'),
        meta: {
            title: '首页',
            requireAuth: true
        }
    },

    {
        path: '/login-in',
        name: 'login-in',
        component: () => import('@/view/login/index.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/mine-in',
        name: 'mine-in',
        component: () => import('@/view/mine-in/index.vue'),
        meta: {
            title: '我的'
        }
    },
    {
        path: '/mine-edit',
        name: 'mine-edit',
        component: () => import('@/view/mine-edit/index.vue'),
        meta: {
            title: '编辑资料'
        }
    },
    {
        path: '/mine-detail',
        name: 'mine-detail',
        component: () => import('@/view/mine-detail/index.vue'),
        meta: {
            title: '查看资料'
        }
    },

    {
        path: '/danmu-page',
        name: 'danmu-page',
        component: () => import('@/view/danmu/index.vue'),
        meta: {
            title: '弹幕'
        }
    },
    {
        path: '/documents',
        name: 'documents',
        component: () => import('@/view/documents/index.vue'),
        meta: {
            title: '文档'
        }
    },
    {
        path: '/sheet-in',
        name: 'sheet-in',
        component: () => import('@/view/sheet/index.vue'),
        meta: {
            title: '文档'
        }
    },


    {
        path: '/chat-square',
        name: 'chat-square',
        component: () => import('@/view/chat-square/index.vue'),
        meta: {
            title: '聊天'
        }
    },
    {
        path: '/about-in',
        name: 'about-in',
        component: () => import('@/view/about-in/index.vue'),
        meta: {
            title: '关于'
        }
    },
    {
        path: '/update-push-log',
        name: 'update-push-log',
        component: () => import('@/view/update-push-log/index.vue'),
        meta: {
            title: '更新日志'
        }
    },
    {
        path: '/theme-settings',
        name: 'theme-settings',
        component: () => import('@/view/theme-settings/index.vue'),
        meta: {
            title: '主题设置'
        }
    },
    {
        path: '/user-subscribe',
        name: 'user-subscribe',
        component: () => import('@/view/user-subscribe/index.vue'),
        meta: {
            title: '关注/粉丝'
        }
    },
    {
        path: '/community',
        name: 'community',
        component: () => import('@/view/community/index.vue'),
        meta: {
            title: '社区'
        }
    },
    {
        path: '/private-letter/:id',
        name: 'private-letter',
        component: () => import('@/view/private-letter/index.vue'),
        meta: {
            title: '私信'
        }
    },
    {
        path: '/tool-in',
        name: 'tool-in',
        component: () => import('@/view/tool-in/index.vue'),
        meta: {
            title: '工具'
        }
    },
    
    
    
]
const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes //上面的路由数组
})
export default router


