

import App from './App.vue';
import { createApp } from 'vue';
import TDesign from 'tdesign-vue-next';
import router from './router/router'
import './utils/axios'
import axios from 'axios';
import VueAxios from "vue-axios";
// 引入组件库的少量全局样式变量
import 'tdesign-vue-next/es/style/index.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'

function isUa() {
    var u = navigator.userAgent,
        app = navigator.appVersion;
    return { //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
    };
}


/**
 * @desc   : 路由登录拦截
 * @author : 于继渤
 * @date   : 2023/4/11 15:42
 */
router.beforeEach((to, from, next) => {

    if(isUa().mobile){
        //跳转移动端
        window.location.href = "https://mobile.yuyue520.online"
    }else{
        if (to.meta.requireAuth) {

            if (localStorage.getItem('userData')) {
                next();
            } else {
                next('/login-in')
            }
        } else {
            next();
        }
    }

    



})




const app = createApp(App);
app.use(TDesign)
app.use(ElementPlus)
app.use(ContextMenu)
app.use(VueAxios, axios)
app.use(router)

app.mount('#app')