<template>
  <t-affix :offset-top="0" v-if="!isMobile">
    <t-head-menu v-if="$route.path != '/login-in'" v-model="menu1Value" theme="light" @change="changeHandler"
      style="border-bottom: 0.5px solid #E8E8E8;z-index: 1;">
      <template #logo>
        <t-icon style="cursor: pointer;" size="28px" name="home"
          v-if="$route.path != '/login-in' && $route.path != '/' && ($route.path == '/documents' || $route.path == '/sheet-in')"
          @click="toHome" />

        <t-icon style="cursor: pointer;" size="28px" name="rollback"
          v-if="$route.path != '/login-in' && $route.path != '/' && $route.path != '/documents' && $route.path != '/sheet-in'"
          @click="toBlack" />
        <div style="cursor: pointer;background-color: #fff;" @click="about">

          <t-image src="https://lingtui-1315959497.cos.ap-beijing.myqcloud.com/20231228110604.png" fit="fill"
            :style="{ width: '170px', height: '55px' }" @error="onError" />
          <!-- <div style="font-size: 12px;font-weight: 600;padding-left: 5px;">0.3.0</div> -->
        </div>



        <div v-if="valueHtml" style="display: flex;justify-content: left;align-items: center;">
          <span style="font-size: 16px;;font-weight: bold;" :style="valueHtml.highFlag == 1 ? 'color:#E2A64B' : ''">{{
            valueHtml.title }}</span>
        <div style="display: flex;justify-content: left;align-items: center;margin-left: 20px;">
              <t-avatar v-if="valueHtml && valueHtml.imagePath" :image="valueHtml.imagePath" size="22px" />
            <div :style="(valueHtml.vipDate && valueHtml.vipDateBeforeCurrent) ? 'color:#CC8B29;' : ''" style="padding-left: 5px;" >{{ valueHtml.userName }}</div>
            </div>


        </div>

      </template>

      <template #operations>

        <div id="he-plugin-simple"></div>

        <!--        <span style="color: rgb(219, 143, 28);cursor: pointer;padding-right: 20px;"-->
        <!--          @click="VipVisibleModeless = true">新年特惠 买一年送二十年</span>-->

        <t-space :size="25">
          <t-button variant="text" shape="square" @click="goToPrivate()">
            <template #icon><t-icon size="25px" name="mail" /></template>
          </t-button>




          <t-popup>
            <!-- <div style="margin-right: 40px;">
              <div class="avatar-container">
                  <img :src="userData.imagePath" class="avatar-image">
                  <div :class="(userData.vipDate && userData.isVipDateBeforeCurrent) ? 'avatar-frame' : ''"></div>
                </div>

            </div> -->

            <t-button variant="text" shape="square" style="margin-right: 50px;">

              <template #icon>

                <t-avatar v-if="userData && userData.imagePath" :image="userData.imagePath" size="30px" />


              </template>


            </t-button>
            <template #content>
              <div style="width: 320px;padding: 15px;">
                <!-- <t-image v-if="userData.dateline" shape="round" style="width:100%;height:100px;" fit="cover"
                  :src="userData.dateline"></t-image> -->

                <div style="display: flex;justify-content: left;align-items: center;">
                  <!-- <div style="background-color:  var(--td-brand-color-2);padding: 4px;border-radius: 50%;width: 55px;">

                    <t-avatar :image="userData.imagePath" size="55px" />
                  </div> -->

                  <div>

                    <div class="avatar-container">
                      <img v-if="userData && userData.imagePath" :src="userData.imagePath" class="avatar-image">
                      <div v-if="userData"
                        :class="(userData.vipDate && userData.isVipDateBeforeCurrent) ? 'avatar-frame' : ''"></div>
                    </div>
                  </div>




                  <div style="padding-top:6px;padding-left:10px;">
                    <div v-if="userData" style="font-size: 15px;font-weight: 700;">{{ userData.userName
                    }}</div>


                    <div @click="openMein"
                      style="text-align: left;display: flex;justify-content: left;align-items: center;cursor: pointer;font-size: 14px;color:#737373;padding-top: 5px;">
                      账号管理<t-icon size="15px" name="chevron-right" />
                    </div>

                    <!-- <div style="font-size: 14px;padding-top: 5px;">{{ '账号管理' }}</div> -->
                  </div>
                </div>


                <div style="background-color: #2F3834;border-radius: 10px;margin-top: 7px;">

                  <div style="display: flex;justify-content: center;align-items: center;padding: 10px;">
                    <div style="width: 64%;color:#C0AB92;font-size: 14px;padding-left: 15px;">

                      <div v-if="userData && userData.vipDate && userData.isVipDateBeforeCurrent"
                        style="font-size: 12px;text-align: left;display: flex;justify-content: left;align-items: center;cursor: pointer;">
                        {{ userData.vipDate + '到期' }}<t-icon size="13px" name="chevron-right" />

                      </div>
                      <div v-if="userData && !userData.vipDate || !userData.isVipDateBeforeCurrent"
                        style="font-size: 13px;color:#C0AB92;">
                        开通会员重获会员特权
                      </div>
                    </div>
                    <div style="width: 36%;text-align: right;">
                      <t-button theme="primary" @click="openVip">
                        <template #icon><add-icon /></template>


                        <span v-if="userData" style="color:#f3ca8d;">{{ (userData.vipDate &&
                          userData.isVipDateBeforeCurrent) ? '续费会员' :
                          '开通会员' }}</span>
                      </t-button>
                    </div>
                  </div>
                  <t-space align="center" class="space-border"
                    style="padding-top: 0px;width: 100%;text-align: center;padding-bottom: 10px;">

                    <div>
                      <div style="font-weight: 700;font-size: 14px;color:#C0AB92;">10+</div>
                      <div style="color:#C0AB92;text-align: center;font-size: 12px;">功能特权</div>

                    </div>
                    <div>
                      <div style="font-weight: 700;font-size: 14px;color:#C0AB92;">10+</div>
                      <div style="color:#C0AB92;text-align: center;font-size: 12px;">个性装扮</div>

                    </div>
                    <div>
                      <div style="font-weight: 700;font-size: 14px;color:#C0AB92;">10+</div>
                      <div style="color:#C0AB92;text-align: center;font-size: 12px;">会员模板</div>

                    </div>


                  </t-space>

                </div>

                <!-- <div
                  style="display: flex;justify-content: left;align-items: center;padding: 15px;cursor: pointer;font-size: 13px;color:#737373">


                  <div style="width: 50%;text-align: left;display: flex;justify-content: left;align-items: center;">
                    <t-icon size="15px" name="control-platform" style="padding-right: 2px;" /><span
                      style="padding-left:8px;font-size:14px">存储空间</span>
                  </div>
                  <div
                    style="width: 50%;text-align: right;display: flex;justify-content: right;align-items: center;font-size:14px">
                    0B / 1GB <t-icon size="15px" name="chevron-right" />
                  </div>
                </div> -->


                <t-space direction="vertical" style="margin-top: 20px;margin-bottom:10px">

                  <t-button theme="default" variant="text" block @click="goThememSettings"
                    style="display: flex;justify-content: left;align-items: center;"><template #icon> <t-icon size="15px"
                        name="palette" /></template> 装扮设置</t-button>

                  <t-button theme="default" variant="text" @click="loginOut" block
                    style="display: flex;justify-content: left;align-items: center;"> <template #icon> <t-icon size="15px"
                        name="logout" /></template> 退出登录</t-button>
                </t-space>

                <!-- <t-divider><span style="font-size:12px">伶推文档</span></t-divider> -->
              </div>
            </template>

          </t-popup>

        </t-space>








      </template>
    </t-head-menu>
  </t-affix>





  <router-view />


  <!-- <div style="position: fixed;bottom: 20px;left:50px;z-index: 99;">
    <img src="./assets/image/_1705501034082.png" @error="setDefaultImage" style="width: 100px;height: 100%;" />

  </div>
  -->



  <t-loading :loading="loading" text="加载中..." fullscreen />

  <t-dialog v-model:visible="isShowDialog" header="警告" width="350px" :confirm-on-enter="true" :on-cancel="onCancel"
    :on-esc-keydown="onEscKeydown" :on-close-btn-click="onCloseBtnClick" :on-overlay-click="onOverlayClick"
    :on-close="close" :on-confirm="onConfirm">
    <t-space direction="vertical" style="width: 100%">
      <div>
        <p>是否注销登录当前账号 ? </p>

      </div>

    </t-space>
  </t-dialog>




  <t-drawer :showOverlay="true" v-model:visible="visibleDrawer" header="消息列表" cancelBtn="" size="50%"
    :on-overlay-click="() => (visibleDrawer = false)">

    <div v-if="messageList.length == 0" style="padding: 10px;">暂无消息</div>
    <!-- 
    <t-list style=" max-height: 800px;" @scroll="scrollHandler">
      <t-space direction="vertical">
        <div style="background-color: #EBEBEB;border-radius: 6px;padding: 10px;cursor: pointer;"
          v-for="item, index in messageList" @click="goToPrivate((item.createId == userData.id ? item.toUserId :item.createId )) ">

          <t-space>

            <t-avatar :image="item.createId == userData.id ? item.imagePath : item.createImagePath" size="40px" />
            <div style="text-align: left;">
              <div style="font-weight: 700;font-size: 15px;">
                {{ item.createId == userData.id ? item.userName : item.createUserName }} <span
                  style="font-weight: 500;font-size: 11px;padding-left: 10px;">{{ formatTime(item.tmessageUserDetail.date) }}</span>
              </div>


              <div style="font-size: 13px;padding-top:10px;">
                <el-text line-clamp="2">
                  <span style="font-weight: 600;color:black">{{ (item.tmessageUserDetail) ?
                    item.tmessageUserDetail.userName + ' 说：' :
                    '' }}</span> {{ (item.tmessageUserDetail) ? item.tmessageUserDetail.messageTxt :
    '' }}

                </el-text>


              </div>
            </div>



          </t-space>


        </div>

      </t-space>
    </t-list> -->



    <!-- <div style="font-size: 13px;">{{ '2023-12-13' }}</div> -->



  </t-drawer>



  <div style="position: fixed;bottom: 0;font-size:12px;text-align: center;width: 100%;">

    <div style="display: flex;justify-content: center;align-items: center;">

      <t-link style="font-size:12px;color:#666666;" href="https://beian.miit.gov.cn/">
        <t-image src="https://lingtui-1315959497.cos.ap-beijing.myqcloud.com/20230927135803.png" shape="round"
          :style="{ width: '16px', height: '16px' }" fit="cover" @error="onError" />辽ICP备2023004647号</t-link>
    </div>

  </div>









  <t-dialog v-model:visible="VipVisibleModeless" mode="modeless" :footer="false" :width="700"
    :on-confirm="() => (VipVisibleModeless = false)">
    <template #header>
      <div style="display: flex;justify-content: left;align-items: center;">
        <div>
          <t-avatar v-if="userData && userData.imagePath" :image="userData.imagePath" size="40px" />
        </div>

        <div style="padding-left:10px;">
          <div v-if="userData" style="font-size: 15px;font-weight: 700;">{{ userData.userName
          }}</div>
        </div>

        <div style="padding-left:10px;">
          <div style="font-size: 12px;color:rgba(0,0,0,.48);">开通即享高效办公体验</div>
        </div>
      </div>
    </template>
    <template #body>
      <div style="display: flex;">
        <div style="width: 30%;">

          <t-space direction="vertical" :size="9" style="padding-top: 15px;">
            <div><t-icon name="check" color="#f3ca8d" size="20px" /> 醒目昵称</div>
            <div><t-icon name="check" color="#f3ca8d" size="20px" /> 专属头像框</div>
            <div><t-icon name="check" color="#f3ca8d" size="20px" /> 个性化特权</div>


          </t-space>






        </div>
        <div style="width: 70%;">


          <t-space>

            <div v-for="item, index  in vipList" :class="item.checked ? 'yse' : 'on'" @click="clickVip(item, index)"
              style="text-align: center;cursor: pointer; width: 130px;height: 160px;border-radius: 10px;">
              <div
                style="color: var(--t-doc-light-text-text-ultrastrong,rgba(0,0,0,.88));font-size: 18px;font-style: normal;font-weight: 600; margin: 32px auto 8px;">
                {{ item.title }}</div>
              <div class="conct" style="padding-top: 5px;"><span
                  style="font-size: 20px;font-style: normal;font-weight: 700;">¥&nbsp;</span>{{ item.money }}</div>
              <div class="conct2" style="padding-top: 5px;">¥&nbsp;{{ item.moneyLable }}</div>
            </div>




          </t-space>


          <t-space style="padding-top: 50px;">

            <t-tag theme="warning" size="large"><span style="font-size: 16px;font-style: normal;">需支付
                ¥&nbsp;</span>{{ vipMoney }}</t-tag>
            <t-button theme="primary" @click="okVip">
              <template #icon><add-icon /></template>
              确认支付
            </t-button>
          </t-space>


          <!-- <div style="display: flex;padding-top: 50px;">

            <div style="width: 154px;">
              <div style="border:2px solid #cc8b29;border-radius: 10px;">
                <t-image src="https://lingtui-1315959497.cos.ap-beijing.myqcloud.com/20231214161737.png"
                  :style="{ width: '150px', height: '150px' }"></t-image>
              </div>




            </div>

            <div style="padding-left: 40px;">



              <div style="padding-top: 40px;margin-left: 70px;">

                <t-tag theme="warning"><span style="font-size: 16px;font-style: normal;">已省 ¥&nbsp;</span>84</t-tag>
              </div>
              <div class="conct" style="padding-top: 20px;"><span
                  style="font-size: 20px;font-style: normal;font-weight: 700;">¥&nbsp;</span>96</div>
            </div>
          </div> -->






        </div>


      </div>



    </template>
  </t-dialog>

  <span class="js-cursor-container"></span>
  <!-- <canvas></canvas> -->
</template>

<script>
import { messageConfig } from 'element-plus';
import { MessagePlugin } from 'tdesign-vue-next';


export default {
  name: 'App',

  data() {
    return {
      userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
      formData: {
        account: '',
        password: ''
      },
      messageList: [],
      valueHtml: {},
      theme: '1',
      loading: false,
      visibleDrawer: false,
      VipVisibleModeless: false,
      isShowDialog: false,
      checked: false,
      drawer: false,
      vipList: [],
      vipMoney: 0
    };
  },


  watch: {
    $route(to, from) {
      // 监听路由变化, 实现类似 小程序的 onShow 事件
      if (to.path === '/') {
        if (localStorage.getItem("userData")) {
          this.userData = JSON.parse(localStorage.getItem("userData"))

        }
      }
      if (to.path === '/documents') {

        if (localStorage.getItem("valueHtml")) {
          this.valueHtml = JSON.parse(localStorage.getItem("documentsDetail"))

        }
      }

    }


  },
  mounted() {


  },
  methods: {
    goToPrivate(e) {
      if (e) {
        this.$router.push({
          name: "private-letter",
          params: { id: e },
        });
      } else {
        this.$router.push({
          name: "private-letter",
          params: { id: 0 },
        });
      }

    },
    clickVip(item, index) {
      this.vipList.forEach(res => {
        res.checked = false
      })
      this.vipList[index].checked = true
      this.vipMoney = this.vipList[index].money
      // if (this.vipList[index].money == 0) {
      //     MessagePlugin.error('支付失败')
      //   }

    },
    okVip() {

      if (this.vipMoney != 0) {
        MessagePlugin.error('支付失败')
      } else {

        if (!this.userData.vipDate) {
          let date = null
          let { time_end, time_kai } = this.timeAdd(false, this.userData.vipDate)
          date = time_end + ' 23:59:59'

          this.updateTime(date)
        } else {
          MessagePlugin.error('已使用过体验版会员，无法再次体验')
        }




      }
    },
    updateTime(date) {
      let param = {}
      param.id = this.userData.id
      param.vipDate = date
      this.axios.post('/api/user/update_user', param).then(res => {

        //请求成功，触发then中的函数
        if (res.data.code === 200) {

          this.VipVisibleModeless = false
          this.getUser()
        }

      })




    },

    getUser() {
      this.loading = true
      this.axios.post('/api/user/get_user', { id: this.userData.id }).then(res => {
        //请求成功，触发then中的函数
        this.loading = false


        if (res.data.code === 200) {
          if (res.data.data) {

            localStorage.setItem("userData", JSON.stringify(res.data.data[0]))
            this.userData = res.data.data[0]
            MessagePlugin.success('支付成功，获得7天会员体验版')
          }

        }

      })

    },
    timeAdd(flag, time) {
      var timestamp = null
      if (time) {
        timestamp = Date.parse(new Date(time));
      } else {
        timestamp = Date.parse(new Date());
      }

      timestamp = timestamp / 1000;
      var n = timestamp * 1000;
      var date = new Date(n);
      //年  
      var Y = date.getFullYear();
      //月  
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      //日  
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var time_kai = Y + '-' + M + '-' + D;
      var tomorrow_timetamp = ''
      //加30天的时间戳：
      if (flag) {
        tomorrow_timetamp = timestamp + 24 * 60 * 60 * 30;
      } else {
        tomorrow_timetamp = timestamp + 24 * 60 * 60 * 7;
      }


      var date_yue = new Date(tomorrow_timetamp * 1000);
      //年  
      var Y_yue = date_yue.getFullYear();
      //月  
      var M_yue = (date_yue.getMonth() + 1 < 10 ? '0' + (date_yue.getMonth() + 1) : date_yue.getMonth() + 1);
      //日  
      var D_yue = date_yue.getDate() < 10 ? '0' + date_yue.getDate() : date_yue.getDate();
      var time_end = Y_yue + '-' + M_yue + '-' + D_yue;

      return {
        time_end: time_end,
        time_kai: time_kai,
      }
    },





    getMessageUser(id) {
      this.axios.post('/api/user/get_message_user', {
        createMeId: id
      }).then(res => {
        this.loading = false;
        //请求成功，触发then中的函数
        if (res.data.code === 200) {

          this.messageList = res.data.data

        }

      })
    },


    goThememSettings() {
      this.$router.push('theme-settings')
    },


    openVisibleDrawer() {
      this.visibleDrawer = true
    },


    openVip() {
      this.vipList = [{
        title: '12个月',
        money: '96',
        moneyLable: '8/月',
        checked: true
      },
      {
        title: '1个月',
        money: '15',
        moneyLable: '15/月',
        checked: false
      },
      {
        title: '7天体验版',
        money: '0',
        moneyLable: '0/0',
        checked: false
      },
      ]
      this.vipMoney = this.vipList[0].money
      this.VipVisibleModeless = true
    },


    onConfirm() {
      this.isShowDialog = false
      this.logout(this.userData.id)

    },
    logout(id) {
      this.axios.post('/api/login/logout', {
        id: id
      }).then(res => {
        //请求成功，触发then中的函数
        this.loading = false
        localStorage.setItem("userData", '')
        localStorage.setItem("checkTagList", '')
        location.reload()
        // this.$router.push('/')
      })
    },





    onCancel() {
      this.isShowDialog = false
    },
    openMein() {
      this.$router.push('mine-in')
    },
    opneDrawer() {
      this.drawer = true
    },
    closeDrawer() {
      this.drawer = false
    },
    loginOut() {
      this.isShowDialog = true

    },
    onChange(e) {
      localStorage.setItem("theme", this.theme)
      if (this.theme == '2') {
        document.documentElement.setAttribute('theme-mode', 'dark');
      } else {
        document.documentElement.removeAttribute('theme-mode');
      }

    },
    formatTime(value) {

      if (value) {
        // 将传入的日期字符串转换为Date对象
        var date = new Date(value);
        // 计算与当前时间的差值（单位为毫秒）
        var diff = Date.now() - date.getTime();
        // 计算天数、小时数和分钟数
        var days = Math.floor(diff / (24 * 60 * 60 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) % 24;
        var minutes = Math.floor(diff / (60 * 1000)) % 60;
        // 根据差值返回相应的时间字符串
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hour = date.getHours()
        const minute = date.getMinutes()
        const second = date.getSeconds()

        return `${[year, month, day].map(this.formatNumber).join('-')} ${[hour, minute, second].map(this.formatNumber).join(':')}`
      }
    },

    formatTimeTo(value) {

      if (value) {
        // 将传入的日期字符串转换为Date对象
        var date = new Date(value);
        // 计算与当前时间的差值（单位为毫秒）
        var diff = Date.now() - date.getTime();
        // 计算天数、小时数和分钟数
        var days = Math.floor(diff / (24 * 60 * 60 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) % 24;
        var minutes = Math.floor(diff / (60 * 1000)) % 60;
        // 根据差值返回相应的时间字符串
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hour = date.getHours()
        const minute = date.getMinutes()
        const second = date.getSeconds()

        return `${[month, day].map(this.formatNumber).join('-')} ${[hour, minute, second].map(this.formatNumber).join(':')}`
      }
    },

    formatNumber(n) {
      n = n.toString()
      return n[1] ? n : `0${n}`
    },
    toBlack() {
      // this.valueHtml = {}
      this.$router.go(-1);
      // this.$router.push('/')
    },
    toHome() {
      this.valueHtml = {}
      // this.$router.go(-1);
      this.$router.push('/')
    },
    login() {
      this.$router.push('login-in')
    },
    about() {
      this.$router.push("about-in")
    },

    isLogin(id) {

      this.axios.post('/api/login/is_login', {
        id: id
      }).then(res => {
        //请求成功，触发then中的函数
        this.loading = false

        if (res.data.code === 200) {
          if (!res.data.data) {
            localStorage.setItem('userData', null)
            this.$router.push('login-in')
          }
        }
      })
    },



  },
  created() {

    if (localStorage.getItem("userData")) {
      // window.WIDGET = {
      //   "CONFIG": {
      //     "modules": "01234",
      //     "background": "4",
      //     "backgroundColor": "FFFFFF",
      //     "tmpColor": "000000",
      //     "tmpSize": "16",
      //     "cityColor": "000000",
      //     "citySize": "16",
      //     "aqiColor": "000000",
      //     "aqiSize": "16",
      //     "weatherIconSize": "24",
      //     "alertIconSize": "18",
      //     "padding": "10px 10px 10px 10px",
      //     "shadow": "0",
      //     "language": "auto",
      //     "fixed": "false",
      //     "vertical": "top",
      //     "horizontal": "left",
      //     "key": "212e56d429644b2bba57190f1e229c95"
      //   }
      // }
      // let script = document.createElement('script')
      // script.type = 'text/javascript'
      // script.src = 'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0'
      // document.getElementsByTagName('head')[0].appendChild(script)

      // let userData = JSON.parse(localStorage.getItem("userData"))
      // var currentDate = new Date();
      // var targetDate = new Date(userData.loginDate);
      // 检查目标日期是否有效
      // if (isNaN(targetDate.getTime())) {
      //   // console.log('上次登录日期无效，请检查日期字符串格式。');
      //   this.$router.push('login-in')
      // } else {
      //   // 计算时间差异（以毫秒为单位）
      //   var timeDifference = currentDate - targetDate;
      //   // 定义30分钟的毫秒数
      //   var thirtyMinutesInMilliseconds = 30 * 60 * 1000;
      //   // 比较两个时间
      //   if (timeDifference > thirtyMinutesInMilliseconds) {
      //     // console.log('当前时间大于上次登录日期30分钟。');
      //     MessagePlugin.info('当前登录会话已过期,请重新登录')
      //     this.$router.push('login-in')
      //   }
      // }
      this.userData = JSON.parse(localStorage.getItem("userData"))

      if (this.userData && this.userData.id) {
        this.isLogin(this.userData.id)
        this.getMessageUser(this.userData.id)
      } else {
        this.$router.push('login-in')
      }

    } else {
      this.$router.push('login-in')
    }
    // document.documentElement.setAttribute('theme-mode', 'dark');


    // if (localStorage.getItem("theme") && this.$route.path != '/login-in') {
    //   document.documentElement.removeAttribute('theme-mode');
    // }

  }
}

</script>
<style >
.custom-navbar {
  --td-navbar-bg-color: #5a5959;
  --td-navbar-color: #fff;
}

.tdesign-demo__custom-popup {
  padding: 0px !important;
}

.t-popup__content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.t-divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.conct {
  color: var(--t-doc-light-text-text-ultrastrong, rgba(0, 0, 0, .88));
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

.conct2 {
  color: var(--t-doc-light-text-text-medium, rgba(0, 0, 0, .48));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  text-align: center;
}

.avatar-container {
  position: relative;
  display: inline-block;

  border-radius: 50%;
}

.avatar-image {
  width: 55px;
  /* 根据需要调整头像的宽度 */
  height: 55px;
  /* 根据需要调整头像的高度 */
  border-radius: 50%;
}

.yse {
  border: 2px solid #cc8b29;


  background: linear-gradient(180deg, hsla(38, 84%, 83%, .4), rgba(255, 231, 170, .1)), #fff;
  transition: border 0.5s ease;
  /* transition: background 0.5s ease; */

}

.on {
  border: 2px solid var(--t-doc-light-border-border-medium, rgba(0, 0, 0, .08));
}

.avatar-frame {
  position: absolute;
  top: -14px;
  right: -12px;
  background-position: 50%;
  width: 82px;
  height: 82px;
  background-size: cover;
  background-image: url('https://lingtui-1315959497.cos.ap-beijing.myqcloud.com/20231227095351.png')
}
</style>


